import omit from "lodash/omit";
import {
  DateField,
  maxLength,
  minLength,
  Pagination,
  ReferenceManyField,
  required,
  SelectInput,
  TabbedShowLayout,
  TextField,
  TextInput,
  TextInputProps,
  WrapperField,
} from "react-admin";
import { UserManagerApi } from "../api/UserManagerApi";
import { EditSelectField } from "../components/EditSelectField";
import MyCreate from "../components/core/MyCreate";
import MyEdit from "../components/core/MyEdit";
import { MyList } from "../components/core/MyList";
import { MyField } from "../components/types";
import { UnLockButton } from "../components/UnLockButton";
import { Admin } from "../model/Admin";
import { BooleanTypeChoices, StatusTypeChoices } from "../model/Core";
import { Field } from "../utils/Field";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import React from "react";
import { MyShowFields } from "../components/MyShowFields";
import { ResourceType } from "../components/core/MyResource";
import { MyShow } from "../components/core/MyShow";
import { ShowCommand } from "../components/command/ShowCommand";
import { CreateCommand } from "../components/command/CreateCommand";
import { EditCommand } from "../components/command/EditCommand";
import { OpenVipCommand } from "./user/OpenVipCommand";
import dayjs from "dayjs";
import { UserTypeOpRecord } from "./user/UserTypeOpRecord";
import { ChatUserDayUsageTable } from "./user/ChatUserDayUsage";
import { UserChatHistory } from "./user/UserChatHistory";

function MyTextInput(props: TextInputProps & { localLabelSource: string }) {
  return <TextInput {...omit(props, "localLabelSource")} />;
}

export function buildUserFields(
  isAgent: boolean,
  isMyUser: boolean
): MyField<Admin>[] {
  return [
    Field.objectId({
      label: "用户ID",
      isList: false,
    }),
    Field.localRef(
      {
        label: "uid",
        source: "uid",
        isFilter: true,
        isEdit: true,
        isCreate: false,
        isUpdate: false,
        reference: "users",
        referenceLabel: "uid",
        originSource: "id",
        localLabelSource: "uid",
      },
      {
        filterProps: {
          source: "uid",
        },
        editComponent: MyTextInput,
      }
    ),
    Field.functionField({
      label: "会员",
      source: "userTypeId",
      isFilter: false,
      minWidth: 180,
      render: (record?: any, source?: string) => {
        if (record.isFree) {
          return record.userTypeName;
        }
        let s = dayjs(record.endDateTime).diff(dayjs(), "day");
        return record.userTypeName + "(剩余" + s + "天)";
      },
    }),
    Field.text({
      label: "邀请码",
      source: "inviteCode",
      isEdit: true,
      isCreate: false,
      isUpdate: false,
    }),
    Field.editSelect({
      label: "是否区域加盟商",
      source: "isAgent",
      choices: BooleanTypeChoices,
      isList: false,
      defaultValue: isAgent,
      isFilter: false,
    }),
    Field.editSelect({
      label: "是否直营店",
      source: "isMyUser",
      choices: BooleanTypeChoices,
      isList: false,
      defaultValue: isMyUser,
      isFilter: false,
    }),
    Field.editSelect({
      label: "是否锁定账户",
      source: "isAccountLock",
      defaultValue: false,
      choices: BooleanTypeChoices,
    }),
    Field.text({
      label: "账号",
      source: "name",
      isEdit: true,
      isCreate: true,
      isUpdate: true,
    }),
    Field.text({
      label: "邮箱",
      source: "email",
      isUpdate: false,
    }),
    Field.text({
      label: "电话",
      source: "phone",
      isUpdate: false,
    }),
    {
      label: "备注",
      source: "desc",
      sortable: false,
      isPrimary: true,
      isFilter: false,
      component: TextField,
      props: {},
      isCreate: true,
      isEdit: true,
      isUpdate: true,
      editComponent: TextInput,
      editProps: {
        validate: [maxLength(512)],
      },
    },
    {
      label: "密码",
      source: "password",
      isList: false,
      isCreate: true,
      isEdit: true,
      isUpdate: true,
      editComponent: TextInput,
      editProps: {
        validate: [minLength(4), maxLength(25)],
      },
    },
    {
      label: "注册时间",
      sortable: true,
      source: "createdDate",
      component: DateField,
      props: {
        showTime: true,
      },
    },
    {
      label: "状态",
      source: "status",
      component: EditSelectField,
      isFilter: false,
      props: {
        choices: StatusTypeChoices,
        alwaysOn: true,
      },
      filterProps: {
        choices: StatusTypeChoices,
        alwaysOn: true,
      },
      isCreate: true,
      isEdit: true,
      isUpdate: true,
      editComponent: SelectInput,
      editProps: {
        defaultValue: "NORMAL",
        choices: StatusTypeChoices,
        validate: [required()],
      },
    },
    {
      label: "登录锁定",
      source: "lock",
      component: WrapperField,
      isFilter: true,
      props: {
        children: (
          <UnLockButton
            onClick={async (r: any) => UserManagerApi.unlock(r.id)}
          />
        ),
      },
    },
  ];
}

const fields = buildUserFields(false, false);

const resource: ResourceType = {
  commands: [
    <ShowCommand />,
    <CreateCommand />,
    <EditCommand />,
    <OpenVipCommand />,
  ],
  show: () => {
    return (
      <MyShow title={`用户`}>
        <TabbedShowLayout value={0}>
          <TabbedShowLayout.Tab label="用户">
            <MyShowFields fields={fields} />
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="会员记录" path="userTypeOpRecords">
            <ReferenceManyField
              reference="userTypeOpRecords"
              target="userId"
              source={"id"}
              label={false}
              pagination={<Pagination />}
            >
              <UserTypeOpRecord />
            </ReferenceManyField>
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="用户日使用金额">
            <ReferenceManyField
              reference="chatUserDayUsage"
              target="userId"
              source={"id"}
              label={false}
              pagination={<Pagination />}
            >
              <ChatUserDayUsageTable />
            </ReferenceManyField>
          </TabbedShowLayout.Tab>
          {/*<TabbedShowLayout.Tab label="钱包">*/}
          {/*  <ReferenceManyField*/}
          {/*    reference="userWallets"*/}
          {/*    target="userId"*/}
          {/*    source={"id"}*/}
          {/*    label={false}*/}
          {/*    pagination={<Pagination />}*/}
          {/*  >*/}
          {/*    <UserWalletTable />*/}
          {/*  </ReferenceManyField>*/}
          {/*</TabbedShowLayout.Tab>*/}

          {/*<TabbedShowLayout.Tab label="帐变" path="userWalletLog">*/}
          {/*  <ReferenceManyField*/}
          {/*    reference="userWalletLog"*/}
          {/*    target="userId"*/}
          {/*    source={"id"}*/}
          {/*    label={false}*/}
          {/*    pagination={<Pagination />}*/}
          {/*  >*/}
          {/*    <UserWalletLog />*/}
          {/*  </ReferenceManyField>*/}
          {/*</TabbedShowLayout.Tab>*/}
          <TabbedShowLayout.Tab label="聊天记录" path="userChatHistory">
            <UserChatHistory />
          </TabbedShowLayout.Tab>
        </TabbedShowLayout>
      </MyShow>
    );
  },
  list: () => {
    return (
      <MyList
        filterDefaultValues={{ isAgent: false, isMyUser: false }}
        fields={fields}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return (
      <MyEdit
        transform={(data) => {
          const omits = [];
          if (data?.password?.length === 0) {
            omits.push("password");
          }
          if (data?.adminPassword?.length === 0) {
            omits.push("adminPassword");
          }
          return omit(data, omits);
        }}
        fields={fields}
      />
    );
  },
  icon: <PermContactCalendarIcon />,
};

export default resource;
