import { DeleteButton, Pagination, useListContext } from "react-admin";
import { ChatMessageModel, ChatRoleChoices } from "../../../model/chat";
import { MyDatagrid } from "../../../components/core/MyDatagrid";
import React from "react";
import { MyField } from "../../../components/types";
import { Field } from "../../../utils/Field";
import { BooleanTypeChoices } from "../../../model/Core";
import Box from "@mui/material/Box";

const fields: MyField[] = [
  Field.objectId(
    {},
    {
      isList: false,
    }
  ),
  Field.select({
    label: "角色",
    source: "role",
    choices: ChatRoleChoices,
  }),
  Field.text({
    label: "模型",
    source: "model",
    minWidth: 130,
  }),
  Field.text(
    {
      label: "消息",
      source: "message",
      multiline: true,
      minWidth: 1000,
    },
    {
      isFilter: false,
    }
  ),
  Field.select({
    label: "是否解析完成",
    source: "ttsOk",
    choices: BooleanTypeChoices,
  }),
  Field.select({
    label: "是否完成",
    source: "completed",
    choices: BooleanTypeChoices,
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

function UserChatHistoryList() {
  const { data, isLoading } = useListContext<ChatMessageModel>();
  console.log("UserChatHistoryList: ", data);
  return (
    <Box>
      <MyDatagrid
        fields={fields}
        rowActions={[
          <DeleteButton
            mutationMode={"pessimistic"}
            confirmTitle={"删除"}
            confirmContent={
              "请确认要删除这条消息吗? 删除后不可恢复, 消息将永久丢失!"
            }
            confirmColor={"warning"}
            redirect={false}
          />,
        ]}
      />
      <Pagination rowsPerPageOptions={[25, 50, 100]} />
    </Box>
  );
}

export default UserChatHistoryList;
