import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";
import { Identifier } from "react-admin";
import { PageData } from "../types";
import { CcsAccount, CcsMessage, CcsMsgForm, CssMsgType } from "../model/ccs";

export const ccsApi = {
  listAccount: async (): Promise<PageData<CcsAccount>> => {
    let { data } = await fetchJson(`${API_URL}/ccsChat/listAccount`, {
      method: "POST",
    });
    return data;
  },
  listMessage: async (acId: string): Promise<PageData<CcsMessage>> => {
    let { data } = await fetchJson(`${API_URL}/ccsChat/listMessage/${acId}`, {
      method: "POST",
    });
    return data;
  },
  sendMsg: async (
    acId: string,
    form: CcsMsgForm
  ): Promise<PageData<CcsMessage>> => {
    let body = new URLSearchParams();
    body.append("msgType", form.msgType.toString());
    body.append("content", form.content);
    if (form.fileName) {
      body.append("fileName", form.fileName);
    }

    let { data } = await fetchJson(`${API_URL}/ccsChat/sendMsg/${acId}`, {
      method: "POST",
      body: body,
    });
    return data;
  },
  readAllByAcId: async (acId: string): Promise<number> => {
    let { data } = await fetchJson(`${API_URL}/ccsChat/readAllByAcId/${acId}`, {
      method: "POST",
    });
    return data;
  },
};
