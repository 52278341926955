import { MyField } from "../../components/types";
import { DateField, DateInput } from "react-admin";
import { base } from "./base";

export interface DateArgs {
  label: string;
  source: string;
  isFilter?: boolean;
}

const defaultArgs: Partial<DateArgs> = {
  isFilter: false,
};

export function date(args: DateArgs, field?: MyField) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: DateField,
      editComponent: DateInput,
      props: {
        showTime: false,
      },
      editProps: {
        sx: { maxWidth: "500px" },
      },
      filterProps: {
        alwaysOn: true,
        clearAlwaysVisible: true,
        resettable: true,
      },
    },
    field
  );
}
