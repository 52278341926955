import React from "react";
import {
  AutocompleteInput,
  NumberInput,
  ReferenceInput,
  TextInput,
} from "react-admin";
import get from "lodash/get";

export const AiSetting: React.FC = (props) => {
  let optionText = (choice?: any) => {
    let value = get(choice, "name");
    return value // the empty choice is { id: '' }
      ? `${value}`
      : "";
  };
  return (
    <>
      <TextInput label="tiktoken服务器" source="setting.tiktokenServer" />
      <NumberInput label="处理历史记录条数" source="setting.history" />
      <NumberInput label="图片数量限制" source="setting.historyImages" />
      <ReferenceInput
        reference={"apiKeyInfos"}
        label="name"
        referenceLabel="name"
        source="setting.greenApiKeyId"
        fullWidth
      >
        <AutocompleteInput
          label="审核密钥"
          optionText={optionText}
        ></AutocompleteInput>
      </ReferenceInput>
      <TextInput
        label="全局提示词"
        maxRows={10}
        multiline
        rows={5}
        source="setting.instructions"
        fullWidth
      />
    </>
  );
};
