import { Box, Divider } from "@mui/material";
import { useCcsAccountData } from "../hooks/useCcsChat";
import CcsHistory from "./CcsHistory";
import CcsSendMsgToolbar from "./CcsSendMsgToolbar";

const CcsSession = () => {
  const { select: selectedAccount } = useCcsAccountData();

  if (!selectedAccount) {
    return <EmptyAccount />;
  } else {
    return (
      <Box display={"flex"} flexDirection={"column"} width={"100%"}>
        <CcsHistory />
        <Divider />
        <CcsSendMsgToolbar />
      </Box>
    );
  }
};

const EmptyAccount = () => {
  return (
    <Box
      flex={1}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      请选择一个用户.
    </Box>
  );
};

export default CcsSession;
