import omit from "lodash/omit";
import { EditButton, ShowButton } from "react-admin";
import MyCreate from "../components/core/MyCreate";
import MyEdit from "../components/core/MyEdit";
import { MyList } from "../components/core/MyList";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import React from "react";
import { buildUserFields } from "./User";

const usersFields = buildUserFields(false, false);

const resource = {
  list: () => {
    return (
      <MyList
        pagination={false}
        fields={usersFields}
        rowActions={[
          <EditButton />,
          <ShowButton />,
          // <AmountButton />,
        ]}
        getTreeDataPath={(r: any) => r.path}
        perPage={1000}
      />
    );
  },
  create: () => {
    return <MyCreate fields={usersFields} />;
  },
  edit: () => {
    return (
      <MyEdit
        transform={(data) => {
          const omits = [];
          if (data?.password?.length === 0) {
            omits.push("password");
          }
          if (data?.adminPassword?.length === 0) {
            omits.push("adminPassword");
          }
          return omit(data, omits);
        }}
        fields={usersFields}
      />
    );
  },
  icon: <PermContactCalendarIcon />,
};

export default resource;
