import React from "react";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import { MyList } from "../../components/core/MyList";
import { EducationStageChoices } from "../../model/chat";
import Box from "@mui/material/Box";
import TempleHinduIcon from "@mui/icons-material/TempleHindu";

const fields: MyField[] = [
  Field.id(),
  Field.editNumber({
    label: "年纪",
    source: "grade",
    defaultValue: 1,
  }),
  Field.editSelect({
    label: "学段",
    source: "eduStage",
    choices: EducationStageChoices,
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return <Box>学习计划 页面</Box>;
  },
  icon: <TempleHinduIcon />,
};

export default resource;
