import { Card, CardContent, Divider } from "@mui/material";
import { FilterList, FilterListItem, useGetList } from "react-admin";
import { ChatThreadModel } from "../../../model/chat";
import { RiChat1Line } from "@remixicon/react";
import Box from "@mui/material/Box";

function UserChatHistoryAside() {
  const { data } = useGetList<ChatThreadModel>("chatThread", {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "threadWeight", order: "DESC" },
  });

  return (
    <Card
      sx={{
        display: { xs: "none", md: "block" },
        order: -1,
        width: "18em",
        mr: 2,
        alignSelf: "flex-start",
      }}
    >
      <CardContent sx={{ pt: 1, pr: 0 }}>
        <FilterList
          label={"会话记录"}
          icon={<RiChat1Line />}
          height={"1050px"}
          overflow={"auto"}
        >
          <Divider />
          {data ? (
            data.map((r) => (
              <FilterListItem
                label={r.title}
                key={r.id}
                value={{ threadId: r.id }}
              />
            ))
          ) : (
            <Box>无聊天数据</Box>
          )}
        </FilterList>
      </CardContent>
    </Card>
  );
}

export default UserChatHistoryAside;
