import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import {
  DefaultCommands,
  ResourceType,
} from "../../components/core/MyResource";
import { StatusTypeChoices } from "../../model/Core";
import { MyShow } from "../../components/core/MyShow";
import { MyShowFields } from "../../components/MyShowFields";
import { RiUserVoiceLine } from "@remixicon/react";
import { SimpleFormIterator, TextInput } from "react-admin";

const fields: MyField[] = [
  Field.objectId(),
  Field.editSelect({
    label: "状态",
    source: "status",
    choices: StatusTypeChoices,
    defaultValue: "NORMAL",
  }),
  Field.ref({
    label: "API密钥",
    source: "apiKeyId",
    isRequired: true,
    reference: "apiKeyInfos",
    referenceLabel: "name",
  }),
  Field.text({
    minWidth: 260,
    label: "名称",
    source: "name",
  }),
  Field.text({
    minWidth: 260,
    label: "模型名称",
    source: "modelName",
  }),
  Field.text({
    minWidth: 260,
    label: "一些第三方api必须(阿里云)",
    source: "appKey",
  }),
  Field.text({
    minWidth: 260,
    label: "程序实现类",
    source: "clsName",
  }),
  Field.iterator({
    minWidth: 260,
    label: "参数",
    source: "args",
    fullWidth: true,
    children: (
      <SimpleFormIterator inline>
        <TextInput
          source="key"
          label={"键"}
          helperText={false}
          required={true}
        />
        <TextInput
          source="value"
          label={"值"}
          helperText={false}
          required={true}
        />
      </SimpleFormIterator>
    ),
  }),
  Field.dateTime(
    {
      label: "创建时间",
      source: "createdDate",
    },
    {
      isList: false,
    }
  ),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource: ResourceType = {
  commands: [...DefaultCommands],
  show: () => {
    return (
      <MyShow>
        <MyShowFields fields={fields} />
      </MyShow>
    );
  },
  list: () => {
    return <MyList fields={fields} pagination={false} perPage={1000} />;
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <RiUserVoiceLine />,
};

export default resource;
