import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import { MyDatagrid } from "../../components/core/MyDatagrid";
import { RiChatVoiceLine } from "@remixicon/react";

const fields: MyField<any>[] = [
  Field.objectId(),
  Field.userField(),
  Field.date({
    label: "日期",
    source: "date",
    isFilter: true,
  }),
  Field.text({
    label: "模型",
    source: "model",
    isFilter: true,
  }),
  Field.amount({
    label: "价格",
    source: "price",
  }),
  Field.text({
    label: "promptTokens",
    source: "promptTokens",
    isFilter: false,
  }),
  Field.text({
    label: "completionTokens",
    source: "completionTokens",
    isFilter: false,
  }),
  Field.text({
    label: "totalTokens",
    source: "totalTokens",
    isFilter: false,
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

export const ChatUserDayUsageTable = () => {
  return <MyDatagrid fields={fields} rowActions={[]} />;
};

const resource = {
  list: () => {
    return <MyList fields={fields} rowActions={[]} />;
  },
  icon: <RiChatVoiceLine />,
};

export const UserTypeOpRecord = () => {
  return <MyDatagrid fields={fields} rowActions={[]} />;
};

export default resource;
