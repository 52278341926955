import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import {
  DefaultCommands,
  ResourceType,
} from "../../components/core/MyResource";
import { StatusTypeChoices } from "../../model/Core";
import { MyShow } from "../../components/core/MyShow";
import { MyShowFields } from "../../components/MyShowFields";
import { RiRobot2Fill } from "@remixicon/react";
import {
  BooleanInput,
  NumberInput,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import { ImageDataTypeChoices } from "../../model/chat";

const fields: MyField[] = [
  Field.objectId(),
  Field.editSelect({
    label: "状态",
    source: "status",
    choices: StatusTypeChoices,
    defaultValue: "NORMAL",
  }),
  Field.ref({
    label: "API密钥",
    source: "apiKeyId",
    isRequired: true,
    reference: "apiKeyInfos",
    referenceLabel: "name",
  }),
  Field.text({
    minWidth: 260,
    label: "名称",
    source: "name",
  }),
  Field.text({
    minWidth: 260,
    label: "程序实现类",
    source: "clsName",
  }),
  Field.boolean({
    label: "开启审核用户输入",
    source: "enableCheckUserMessage",
  }),
  Field.boolean({
    label: "开启审核AI输出",
    source: "enableAssistantMessage",
  }),
  Field.text({
    label: "简介",
    source: "desc",
    isRequired: true,
    minWidth: 200,
  }),
  Field.editSelect({
    label: "图片数据类型",
    source: "imageDataType",
    choices: ImageDataTypeChoices,
    defaultValue: "NORMAL",
  }),
  Field.iterator({
    label: "模型",
    fullWidth: true,
    source: "aiModels",
    children: (
      <SimpleFormIterator inline>
        <TextInput
          source="name"
          label={"名称"}
          helperText={false}
          required={true}
        />
        <TextInput
          source="nativeApiModeName"
          label={"api模型名称"}
          helperText={false}
          required={true}
        />
        <TextInput
          source="remark"
          label={"备注"}
          helperText={false}
          required={true}
        />
        <NumberInput
          source="promptPrice"
          label={"100万输入TOKEN价格"}
          helperText={false}
          required={true}
        />
        <NumberInput
          source="completionPrice"
          label={"100万输出TOKEN价格"}
          helperText={false}
          required={true}
        />
        <BooleanInput
          source="enableRetrieval"
          label={"检索文件"}
          defaultValue={false}
        />
        <BooleanInput
          source="enableChatRetrieval"
          label={"聊天检索文件"}
          helperText={"是否支持用户在聊天框上传文件进行检索"}
          defaultValue={false}
        />
        <BooleanInput
          source="enableImages"
          label={"图片识别"}
          defaultValue={false}
        />
        <BooleanInput
          source="enableAutoVoice"
          label={"语音对话"}
          defaultValue={false}
        />
        <BooleanInput
          source="enableMessageToVoice"
          label={"语音朗读"}
          defaultValue={false}
        />
      </SimpleFormIterator>
    ),
  }),
  Field.iterator({
    minWidth: 260,
    label: "参数",
    source: "args",
    fullWidth: true,
    children: (
      <SimpleFormIterator inline>
        <TextInput
          source="key"
          label={"键"}
          helperText={false}
          required={true}
        />
        <TextInput
          source="value"
          label={"值"}
          helperText={false}
          required={true}
        />
      </SimpleFormIterator>
    ),
  }),
  Field.maxText({
    minWidth: 100,
    label: "公共提示词",
    isRequired: false,
    source: "instructions",
    multiline: true,
    minRows: 4,
  }),
  Field.dateTime(
    {
      label: "创建时间",
      source: "createdDate",
    },
    {
      isList: false,
    }
  ),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource: ResourceType = {
  commands: [...DefaultCommands],
  show: () => {
    return (
      <MyShow>
        <MyShowFields fields={fields} />
      </MyShow>
    );
  },
  list: () => {
    return <MyList fields={fields} pagination={false} perPage={1000} />;
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <RiRobot2Fill />,
};

export default resource;
