import React, { useRef, useState, useEffect } from "react";
import { Box, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { voiceActorApi } from "../../api/VoiceActorApi";
import Hls from "hls.js";

export interface AudioPlayerProps {
  voiceActorId: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ voiceActorId }) => {
  const [message, setMessage] = useState<string>(
    "小明高兴地说：“哈哈，我考了全班第一！”"
  );
  const [playing, setPlaying] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const hlsRef = useRef<Hls | null>(null);

  useEffect(() => {
    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }
    };
  }, []);

  const loadAudio = async () => {
    setLoading(true);
    try {
      const ttsUrl = await voiceActorApi.tts(voiceActorId, message);
      if (Hls.isSupported()) {
        hlsRef.current = new Hls();
        hlsRef.current.loadSource(ttsUrl);
        hlsRef.current.attachMedia(audioRef.current!);
        hlsRef.current.on(Hls.Events.MANIFEST_PARSED, () => {
          audioRef.current?.play();
        });
      } else if (
        audioRef.current?.canPlayType("application/vnd.apple.mpegurl")
      ) {
        audioRef.current.src = ttsUrl;
        audioRef.current.addEventListener("loadedmetadata", () => {
          audioRef.current?.play();
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePlayPause = async () => {
    if (playing) {
      audioRef.current?.pause();
    } else {
      await loadAudio();
    }
    setPlaying(!playing);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <TextField
        label="内容:"
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        margin="normal"
      />
      <Box display="flex" alignItems="center">
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={handlePlayPause}
        >
          {playing ? "暂停" : "播放"}
        </LoadingButton>
        <audio ref={audioRef} controls style={{ marginLeft: 16 }} />
      </Box>
    </Box>
  );
};

export default AudioPlayer;
