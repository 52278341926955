import React from "react";
import { useQuery } from "react-query";
import { ccsApi } from "../../../api/CcsChatApi";
import { CcsMessage, CcsMsgForm, CssMsgType } from "../../../model/ccs";
import { useNotify } from "react-admin";

export interface CcsMessageContext {
  data: CcsMessage[];
  isLoading: boolean;
  isError: boolean;
  ccsSendMsg: (acId: string) => Promise<void>;
  msg: string;
  onChangeMsg: (newMsg: string) => void;
}

const useCcsMessage = (acId?: string): CcsMessageContext => {
  const notify = useNotify();
  const [selectMessage, setSelectMessage] = React.useState<string>();
  const [msg, setMsg] = React.useState<string>("");

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(20);
  const [total, setTotal] = React.useState(0);

  const { isLoading, isError, error, data, refetch } = useQuery(
    ["ccsChat/listMessage", acId],
    async () => {
      if (acId) {
        const pageData = await ccsApi.listMessage(acId);
        setPage(pageData.number);
        setPageSize(pageData.size);
        setTotal(pageData.totalElements);
        return pageData.content;
      } else {
        return [];
      }
    },
    {
      onSuccess: (data) => {},
    }
  );

  const ccsSendMsg = async (acId: string) => {
    if (msg.trim().length === 0) {
      notify("消息不能为空!");
      return;
    }
    let form: CcsMsgForm = {
      msgType: CssMsgType.TEXT,
      content: msg,
    };
    await ccsApi.sendMsg(acId, form);
    await refetch();
    setMsg("");
  };

  return {
    data: data,
    isLoading: isLoading,
    isError: isError,
    ccsSendMsg: ccsSendMsg,
    msg: msg,
    onChangeMsg: setMsg,
  } as CcsMessageContext;
};

export default useCcsMessage;
