export interface CcsAccount {
  id: string;
  phone: string;
  lastMsgDate: string;
  lastMsgContent?: string;
  clientUnread: number;
  ccsUnread: number;
  createdDate: string;
  updateDate: string;
  version: number;
}

export interface CcsMessage {
  id: string;
  ccsAccountId: string;
  sender: string;
  msgType: CssMsgType;
  content: string;
  fileName?: string;
  ccsRead: boolean;
  createdDate: Date;
  updateDate: Date;
  version: number;
}

export enum CssMsgType {
  TEXT = "TEXT",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  FILE = "FILE",
}

export interface CcsMsgForm {
  msgType: CssMsgType;
  content: string;
  fileName?: string;
}
