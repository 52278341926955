import CcsAccountList from "./CcsAccountList";
import CcsSession from "./CcsSession";
import { Box, Divider } from "@mui/material";
import React from "react";
import { CcsProvider } from "../hooks/useCcsChat";

const CcsChat = () => {
  return (
    <CcsProvider>
      <Box
        sx={{
          display: "flex",
          height: "80%",
          width: "100%",
          bgcolor: "background.paper",
          lineHeight: 1,
        }}
      >
        <CcsAccountList />
        <Divider orientation={"vertical"} />
        <CcsSession />
      </Box>
    </CcsProvider>
  );
};

export default CcsChat;
