import React, { createContext, PropsWithChildren, useContext } from "react";
import useCcsAccount, { CcsAccountContext } from "./useCcsAccount";
import useCcsMessage, { CcsMessageContext } from "./useCcsMessage";

interface CcsContextData {
  ccsAccountData: CcsAccountContext;
  ccsMessageData: CcsMessageContext;
}

const CcsContext = createContext<CcsContextData | undefined>(undefined);

export const CcsProvider = ({ children }: PropsWithChildren) => {
  const ccsAccountData = useCcsAccount();

  const ccsMessageData = useCcsMessage(ccsAccountData.selectId);

  return (
    <CcsContext.Provider
      value={{
        ccsAccountData: ccsAccountData,
        ccsMessageData: ccsMessageData,
      }}
    >
      {children}
    </CcsContext.Provider>
  );
};

export function useCssContext(): CcsContextData {
  const cssContext = useContext(CcsContext);
  if (!cssContext) {
    throw new Error("useCssContext must be used within a CcsProvider");
  }
  return cssContext;
}

export const useCcsAccountData = (): CcsAccountContext => {
  const { ccsAccountData } = useCssContext();
  return ccsAccountData;
};

export const useCcsMessageData = (): CcsMessageContext => {
  const { ccsMessageData } = useCssContext();
  return ccsMessageData;
};
