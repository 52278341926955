import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import { useCcsAccountData, useCcsMessageData } from "../hooks/useCcsChat";

const CcsSendMsgToolbar = () => {
  return (
    <Box display={"flex"} padding={"12px"}>
      <LeftButtons />
      <CcsMsgInput />
      <CcsMsgSendBtn />
    </Box>
  );
};

const CcsMsgSendBtn = () => {
  const { selectId } = useCcsAccountData();
  const { ccsSendMsg } = useCcsMessageData();
  return (
    <Button
      variant={"contained"}
      onClick={async () => {
        await ccsSendMsg(selectId!);
      }}
    >
      发送
    </Button>
  );
};

const CcsMsgInput = () => {
  const { msg, onChangeMsg } = useCcsMessageData();
  return (
    <Box flex={1} padding={"0 8px"}>
      <TextField
        fullWidth
        variant={"standard"}
        value={msg}
        onChange={(e) => onChangeMsg(e.target.value)}
        sx={{
          "& .MuiTextField-root.Mui-focused": {
            borderBottom: undefined,
          },
        }}
      />
    </Box>
  );
};

const LeftButtons = () => {
  return <></>;
};

export default CcsSendMsgToolbar;
