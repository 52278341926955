import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";
import { Identifier } from "react-admin";

export const voiceActorApi = {
  tts: async (voiceActorId: string, message: string): Promise<string> => {
    let { data } = await fetchJson(`${API_URL}/voiceActor/tts`, {
      method: "POST",
      body: new URLSearchParams({
        voiceActorId,
        message,
      }),
    });
    return data;
  },
};
