import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import React from "react";
import CcsChat from "./components/CcsChat";

const resource = {
  icon: <SupportAgentIcon />,
  list: () => {
    return <CcsChat />;
  },
};

export default resource;
