import { CommandProps } from "../../components/command/Command";
import {
  BaseCommand,
  BaseCommandProps,
} from "../../components/command/BaseCommand";
import { useRecordContext } from "ra-core";
import { CloudSync } from "@mui/icons-material";
import React from "react";
import get from "lodash/get";
import { useConfirmForm } from "../../hook/ConfirmContext";
import AudioPlayer from "./AudioPlayer";

export interface PlayerCommandProps extends CommandProps {}

PlayerCommand.defaultProps = {
  enableTool: false,
  enableRow: true,
  permission: "update",
} as BaseCommandProps;

export function PlayerCommand(props: PlayerCommandProps) {
  const { ...rest } = props;
  const record = useRecordContext(props);
  const id = get(record, "id");
  const confirm = useConfirmForm();
  return (
    <BaseCommand
      command={{
        isEnable: (rowData: any) => {
          return true;
        },
        label: (r) => {
          return "播放";
        },
        icon: <CloudSync />,
        onHandle: async (_, r) => {
          const form = await confirm({
            title: `播放[${r.name}]的声音`,
            form: (
              <>
                <AudioPlayer voiceActorId={r.id}></AudioPlayer>
              </>
            ),
          });
          //
          if (form) {
          }
          return true;
        },

        // to: (record, rd) => `#/${rd?.name}/create`,
      }}
      {...rest}
    />
  );
}
