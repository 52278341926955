import React from "react";
import Box from "@mui/material/Box";
import UserChatHistoryAside from "./components/UserChatHistoryAside";
import UserChatHistoryList from "./components/UserChatHistoryList";
import { ListBase, useRecordContext } from "ra-core";
import { List, Pagination } from "react-admin";

export const UserChatHistory = () => {
  const user = useRecordContext();
  return (
    <List
      resource={"chatMessage"}
      perPage={25}
      filterDefaultValues={{ userId: user.id }}
      sort={{ field: "createdDate", order: "DESC" }}
    >
      <Box display="flex" height={"1100px"}>
        <UserChatHistoryAside />
        <UserChatHistoryList />
      </Box>
    </List>
  );
};
