import omit from "lodash/omit";
import { TabbedShowLayout } from "react-admin";
import MyCreate from "../components/core/MyCreate";
import MyEdit from "../components/core/MyEdit";
import { MyList } from "../components/core/MyList";
import React from "react";
import { MyShowFields } from "../components/MyShowFields";
import { ResourceType } from "../components/core/MyResource";
import { MyShow } from "../components/core/MyShow";
import { ShowCommand } from "../components/command/ShowCommand";
import { CreateCommand } from "../components/command/CreateCommand";
import { EditCommand } from "../components/command/EditCommand";
import { OpenVipCommand } from "./user/OpenVipCommand";
import { buildUserFields } from "./User";
import StoreIcon from "@mui/icons-material/Store";

const fields = buildUserFields(true, true);

const resource: ResourceType = {
  commands: [
    <ShowCommand />,
    <CreateCommand />,
    <EditCommand />,
    <OpenVipCommand />,
  ],
  show: () => {
    return (
      <MyShow title={`直营店`}>
        <TabbedShowLayout value={0}>
          <TabbedShowLayout.Tab label="用户">
            <MyShowFields fields={fields} />
          </TabbedShowLayout.Tab>
        </TabbedShowLayout>
      </MyShow>
    );
  },
  list: () => {
    return (
      <MyList
        filterDefaultValues={{ isAgent: true, isMyUser: true }}
        fields={fields}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return (
      <MyEdit
        transform={(data) => {
          const omits = [];
          if (data?.password?.length === 0) {
            omits.push("password");
          }
          if (data?.adminPassword?.length === 0) {
            omits.push("adminPassword");
          }
          return omit(data, omits);
        }}
        fields={fields}
      />
    );
  },
  icon: <StoreIcon />,
};

export default resource;
