import { Box, Card, List, ListItem, ListItemText } from "@mui/material";
import { useCcsMessageData } from "../hooks/useCcsChat";
import { useEffect, useRef } from "react";

const CcsHistory = () => {
  const { data, isLoading } = useCcsMessageData();
  const historyDivRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (historyDivRef.current) {
      historyDivRef.current.scrollTop = historyDivRef.current.scrollHeight;
    }
  }, [data, historyDivRef]);

  return (
    <Box flex={1} overflow={"auto"} ref={historyDivRef}>
      <List sx={{ display: "flex", flexDirection: "column-reverse" }}>
        {data?.map((d) => (
          <ListItem
            key={d.id}
            sx={{
              justifyContent: d.ccsAccountId === d.sender ? "left" : "flex-end",
            }}
          >
            <Card
              sx={{
                padding: "8px",
              }}
            >
              {d.content}
            </Card>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default CcsHistory;
