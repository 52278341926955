import {
  Badge,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import { useCcsAccountData, useCcsMessageData } from "../hooks/useCcsChat";
import MailIcon from "@mui/icons-material/Mail";

const CcsAccountList = () => {
  const { selectId, onChange, data, isLoading, isError } = useCcsAccountData();
  const { onChangeMsg } = useCcsMessageData();
  return (
    <Box sx={{ width: "100%", maxWidth: 360, padding: 2, overflow: "auto" }}>
      <List component="nav" aria-label="main mailbox folders">
        {data?.map((d) => (
          <React.Fragment key={d.id}>
            <ListItemButton
              selected={d.id === selectId}
              onClick={(event) => {
                if (d.id !== selectId) {
                  d.ccsUnread = 0;
                  onChange(d.id);
                  onChangeMsg("");
                }
              }}
            >
              <ListItem
                secondaryAction={
                  d.ccsUnread > 0 ? (
                    <Badge color="error" badgeContent={d.ccsUnread}>
                      <MailIcon />
                    </Badge>
                  ) : (
                    <></>
                  )
                }
              >
                <ListItemText primary={`${d.phone}`} />
              </ListItem>
            </ListItemButton>
            <Divider variant="inset" component="li" />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default CcsAccountList;
