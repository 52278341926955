import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { ccsApi } from "../../../api/CcsChatApi";
import { CcsAccount } from "../../../model/ccs";

export interface CcsAccountContext {
  selectId?: string;
  onChange: (id: string) => void;
  select?: CcsAccount;
  data: CcsAccount[];
  isLoading: boolean;
  isError: boolean;
}

const useCcsAccount = (): CcsAccountContext => {
  const [selectAccount, setSelectAccount] = React.useState<string>();

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(20);
  const [total, setTotal] = React.useState(0);

  const { isLoading, isError, error, data, refetch } = useQuery(
    ["ccsChat/listAccount"],
    async () => {
      const pageData = await ccsApi.listAccount();
      setPage(pageData.number);
      setPageSize(pageData.size);
      setTotal(pageData.totalElements);
      return pageData.content;
    },
    {
      onSuccess: (data) => {},
    }
  );

  useEffect(() => {
    const interval = setInterval(async () => {
      await refetch();
      if (selectAccount) {
        let currAc = data?.find((d) => d.id === selectAccount);
        if (currAc && currAc.ccsUnread > 0) {
          currAc.ccsUnread = 0;
          ccsApi.readAllByAcId(selectAccount);
        }
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const changeSelectAccount = (id: string) => {
    ccsApi.readAllByAcId(id);
    setSelectAccount(id);
  };

  return {
    selectId: selectAccount,
    onChange: changeSelectAccount,
    select: data?.find((d) => d.id === selectAccount),
    data: data,
    isLoading: isLoading,
    isError: isError,
  } as CcsAccountContext;
};

export default useCcsAccount;
