import React from "react";
import { AutocompleteInput, ReferenceInput, TextInput } from "react-admin";
import get from "lodash/get";

export const UserSetting: React.FC = (props) => {
  let optionText = (choice?: any) => {
    let value = get(choice, "defaultTitle");
    return value // the empty choice is { id: '' }
      ? `${value}`
      : "";
  };
  return (
    <>
      <ReferenceInput
        reference={"userTypes"}
        label="defaultTitle"
        referenceLabel="defaultTitle"
        source="setting.defaultUserType"
        fullWidth
      >
        <AutocompleteInput
          label="默认用户类型"
          optionText={optionText}
        ></AutocompleteInput>
      </ReferenceInput>
    </>
  );
};
