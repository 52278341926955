import { GridRenderCellParams, GridRowParams } from "@mui/x-data-grid-pro";
import * as React from "react";
import { ReactElement } from "react";
import { RecordContextProvider } from "react-admin";

export type MyGridActionsCellItemProps = {
  params: GridRenderCellParams<any, any, any>;
  button: ReactElement;
};

const BaseGridActionsCellItem = (props: MyGridActionsCellItemProps) => {
  const { params, button } = props;

  return (
    <RecordContextProvider<any> value={params.row}>
      {button}
    </RecordContextProvider>
  );
};

export { BaseGridActionsCellItem };
